@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;700&display=swap';

@font-face {
  font-family: 'PT Sans';
  src: url('assets/fonts/PTSans-Regular.woff') format('woff');
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'DNC';
  src: url('assets/fonts/DNC.woff') format('woff');
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}

body {
  background-color: #B3B3B3;
}

body.hidden {
  overflow: hidden;
}

.learning-item {
  position: fixed !important;
  margin: 0 !important;
  z-index: 10000 !important;
}

.learning-item div,
.learning-item a {
  margin: 0 !important;
}

.learning-item div:nth-child(1):after,
.learning-item div:nth-child(1):before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: #fff;
}

.learning-item div:nth-child(1):after {
  width: calc(100% + 4rem);
  height: calc(100% + 4rem);
  z-index: -2;
}

@media screen and (max-width: 500px) {
  .learning-item div:nth-child(1):after {
    width: calc(100% + 3rem);
    height: calc(100% + 3rem);
  }
}

.learning-item div:nth-child(1):before {
  width: calc(100% + 3rem);
  height: calc(100% + 3rem);
  border: 0.2rem solid rgba(19, 46, 140, 0.7);
  z-index: -1;
}

@media screen and (max-width: 500px) {
  .learning-item div:nth-child(1):before {
    width: calc(100% + 2rem);
    height: calc(100% + 2rem);
  }
}
