.rdrCalendarWrapper {
  font-size: 1.4rem;
  margin: 3em auto;
  touch-action: manipulation;
}

@media screen and (max-width: 600px) {
  .rdrCalendarWrapper select {
    font-size: 16px;
  }
}

.rdrSelected {
  border-radius: 50%;
}

.rdrCalendarWrapper * {
  touch-action: manipulation;
  outline: none;
}

.rdrMonth .rdrWeekDays {
  border-bottom: 0.1em solid #f1f3f9;
}

.rdrWeekDay {
  text-transform: uppercase;
}

.rdrDayPassive .rdrDayNumber span {
  color: #dcdfe5;
}

.rdrDayNumber span {
  color: #687591;
}

.rdrMonthAndYearPickers select {
  text-transform: uppercase;
}

.rdrStartEdge {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.rdrEndEdge {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
.rdrDayEndOfMonth .rdrInRange,
.rdrDayEndOfMonth .rdrStartEdge,
.rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfWeek .rdrStartEdge {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfMonth .rdrEndEdge,
.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrEndEdge {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.rdrDay {
  height: 4.1em;
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
  border-radius: 50%;
}

.rdrDayStartOfMonth .rdrDayInPreview,
.rdrDayStartOfMonth .rdrDayEndPreview,
.rdrDayStartOfWeek .rdrDayInPreview,
.rdrDayStartOfWeek .rdrDayEndPreview {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.rdrDayEndOfMonth .rdrDayInPreview,
.rdrDayEndOfMonth .rdrDayStartPreview,
.rdrDayEndOfWeek .rdrDayInPreview,
.rdrDayEndOfWeek .rdrDayStartPreview {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.rdrDayEndPreview {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.rdrDayStartPreview {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.rdrMonth {
  padding: 0;
  margin: 0 auto;
}

.rdrDateDisplayWrapper {
  width: 0;
  height: 0;
  display: none;
}
